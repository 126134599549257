<template>
  <div>
    <b-card title="Abilita l'utilizzo dei cap">
      <b-form-checkbox
        v-model="usecap"
        :checked="usecap"
        name="check-button"
        switch
        inline
      >
        <span v-if="usecap">
          utilizzo CAP abilitato
        </span>
        <span v-else>
          utilizzo CAP disabilitato
        </span>
      </b-form-checkbox>
      <b-form-input
        v-if="!usecap"
        id="printed"
        v-model="nocapPrinted"
        type="number"
        class="mt-1"
        placeholder="Numero massimo vincitori possibili"
      /><br>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="saveConfigCap"
      >
        Salva configurazione
      </b-button>
    </b-card>
    <b-card
      v-if="usecap"
      title="Cap attivi"
    >
      <b-card-text>Questi sono i cap attivi e il numero di vincite massime su cui distribuire i premi.</b-card-text>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Cerca"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="deleteCap(props.formattedRow['cap'])">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Elimina</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Mostra
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> per volta </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <br>
      <b-button
        v-b-modal.addCapModal
        variant="gradient-primary"
      >
        Aggiungi nuovo CAP
      </b-button>
      <b-modal
        id="addCapModal"
        ref="addCapModal"
        cancel-variant="outline-secondary"
        ok-title="Aggiungi"
        cancel-title="Annulla"
        centered
        title="Aggiungi un nuovo cap"
        @ok="addNewCap"
      >
        <validation-observer ref="validateAddCap">
          <validation-provider
            name="Country"
            rules="required"
          >
            <b-form
              @submit.prevent
            >
              <b-form-group>
                <label for="city">Nome città:</label>
                <b-form-input
                  id="city"
                  v-model="newcap.city"
                  type="text"
                  placeholder="Nome città"
                  rules="required"
                />
              </b-form-group>
              <b-form-group>
                <label for="cap">CAP:</label>
                <b-form-input
                  id="cap"
                  v-model="newcap.cap"
                  type="number"
                  placeholder="CAP"
                  rules="required"
                />
              </b-form-group>
              <b-form-group>
                <label for="print">Numero premi:</label>
                <b-form-input
                  id="print"
                  v-model="newcap.printed"
                  type="number"
                  placeholder="Numero premi"
                  rules="required"
                />
              </b-form-group>
            </b-form>
          </validation-provider>
        </validation-observer>
      </b-modal>
    </b-card>
    <b-card>
      <h4>Premi attivi:</h4>
      <ul>
        <li>
          10% di sconto <b>|</b>
          <span class="possibility"> 30% di possibilità di vincita</span>
        </li>
        <li>
          15% di sconto <b>|</b>
          <span class="possibility"> 20% di possibilità di vincita</span>
        </li>
        <li>
          Dessert in omaggio <b>|</b>
          <span class="possibility"> 25% di possibilità di vincita</span>
        </li>
        <li>
          Una bottiglia di prosecco <b>|</b>
          <span class="possibility"> 15% di possibilità di vincita</span>
        </li>
        <li>
          Buono sconto 2x1 <b>|</b>
          <span class="possibility"> 10% di possibilità di vincita</span>
        </li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormCheckbox, BButton, BCardText, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BCard,
    BButton,
    BCardText,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      usecap: true,
      nocapPrinted: null,
      pageLength: 5,
      required,
      dir: false,
      newcap: {
        cap: '',
        province: '',
        printed: '',
        city: '',
      },
      columns: [
        {
          label: 'Nome città',
          field: 'city',
        },
        {
          label: 'Cap',
          field: 'cap',
        },
        {
          label: 'Numero premi',
          field: 'printed',
        },
        {
          label: 'Azioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getCaps()
  },
  methods: {
    deleteCap(cap) {
      this.$bvModal
        .msgBoxConfirm('Sei sicuro di voler eliminare questo cap? Gli utenti non verranno eliminati.', {
          cancelVariant: 'outline-secondary',
          cancelTitle: 'Annulla',
        })
        .then(value => {
          if (value) {
            this.$http.delete(`/easter/slotmachine/cap/${cap}`).then(() => {
              this.getCaps()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cap eliminato correttamente.',
                  icon: 'AlertCircleIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Impossibile eliminare cap.',
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
            })
          }
        })
    },
    handleSubmit() {
      this.$nextTick(() => {
        this.$refs.addCapModal.toggle('#toggle-btn')
      })
    },
    addNewCap(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.validateAddCap.validate().then(success => {
        if (success) {
          this.handleSubmit()
          this.$http.post('/easter/slotmachine/cap', this.newcap).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Cap inserito correttamente.',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.getCaps()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Errore nell\'aggiunta del cap.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
          this.newcap.cap = ''
          this.newcap.city = ''
          this.newcap.printed = ''
          this.newcap.province = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Controlla i dati inseriti.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        }
      })
    },
    saveConfigCap() {
      let capint = null
      if (this.usecap) {
        capint = 0
      } else {
        capint = 1
      }
      this.$http.post(`/easter/slotmachine/cap/use/${capint}/${this.nocapPrinted}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Configurazione salvata correttamente.',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel salvataggio della configurazione.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    getCaps() {
      this.$http.get('/easter/slotmachine/cap/use').then(response => {
        const x = response.data.find(el => el.metakey === 'dontusecaps')
        if (x.metavalue === 0) {
          this.usecap = true
        } else {
          this.usecap = false
        }
        this.nocapPrinted = response.data.find(el => el.metakey === 'nocaps_printed').metavalue
      })
      this.$http.get('/easter/slotmachine/cap').then(response => {
        this.rows = response.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel recupero dei cap.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style scoped>
  .possibility{
    color: darkred;
  }
</style>
